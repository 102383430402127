import { LineStringGeometry, PointGeometry } from '@/helpers/types/geometry';

/* eslint-disable no-unused-vars */
type Guidance = {
  geometry: PointGeometry;
  distanceMeter: number;
  directon: string;
  type: string;
  roundAboutExit: number;
};

export type Route = {
  distanceMeter: number;
  durationSecond: number;
  geometry: LineStringGeometry;
  paidHighwayLength: number;
  paidMotorwayLength: number;
  paidPrice: number;
  guidance: Array<Guidance>;
  paidSegments: Array<PaidSegment>;
  legality?: Array<Record<string, any>>;
  error?: Record<string, any>;
};

export type Waypoint = {
  city: string;
  fullAddress: string;
  deliveryCoords: Array<number>;
};

export type PaidSegment = {
  direction: string;
  edid: string;
  geometry: LineStringGeometry;
};

export type ImportedRoute = {
  freightId: string;
  route?: Route;
  comment?: string;
  waypoints: Array<Waypoint>;
  originalAddresses: Array<string>;
  orderedWaypoints: Array<string>;
  errorMessages: Array<RouteImportErrorsMessage>;
};

export enum RouteImportErrorTypes {
  MISSING_REQUIRED_FIELD,
  WAYPOINTS_LENGTH,
  CANNOT_BE_GEOCODED,
  NO_ROUTE_FOUND
}

export type RouteImportErrorsMessage = {
  type: RouteImportErrorTypes;
  message: string;
  field: string;
};

export enum RoutingMethod {
  ECONOMY = 'ECONOMY',
  FAST = 'FAST',
  SHORT = 'SHORT'
}

export type RouteParams = {
  vehicleCategory: string;
  euroCategory: string;
  weight: number;
  axleWeight: number;
  height: number;
  width: number;
  length: number;
  useFallback: boolean;
  ferry: boolean;
  motorway: boolean;
  waypoints: any;
  guidance?: boolean;
};

export enum WaypointTypes {
  HARD = 'hard',
  SOFT = 'soft'
}

export enum WaypointPlaces {
  END = 'end',
  MID = 'mid'
}

export type MarkerOptions = {
  color: string;
  scale: number;
  draggable: boolean;
  type?: WaypointTypes;
  element?: HTMLElement;
};

export type PrintRouteParameters = {
  vehicleType?: string;
  vehicleCategory?: string;
  euroCategory?: string;
  weight: number;
  axleWeight: number;
  height: number;
  width: number;
  length: number;
  ferry: boolean;
  motorway: boolean;
};
